<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters">
                <div class="col l-3 m-3 c-12">
                    <comment :objectType="57" :objectId="this.$route.params.id" />
                </div>
                <div class="col l-9 m-9 c-12">
                    <div class="page-list-body">
                        <div class="row no-gutters page-list-header">
                            <div class="col l-6 m-6 c-12">
                                <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Dự toán {{ entity.type }}</span></h4>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="page-list-action">
                                    <md-button v-shortkey="['ctrl', 'e']" @shortkey="preview()" @click="preview()" class="md-raised md-view btn-add"><span>E</span>xport<md-tooltip>Export dự toán (Ctrl + E)</md-tooltip></md-button>
                                    <md-button v-if="entity.files.length > 0" v-shortkey="['ctrl', 'x']" @shortkey="viewAttachFile()" @click="viewAttachFile()" class="md-raised md-view btn-add"><span>X</span>em file đính kèm<md-tooltip>Xem file đính kèm (Ctrl + X)</md-tooltip></md-button>
                                </div>
                            </div>
                        </div>
                        <div class="form-body">
                            <div class="wrapper">
                                <div class="row no-gutters">
                                    <div class="col l-4 m-4 c-12">
                                        <div class="left">
                                            <img src="../../../assets/images/logo.png" />
                                        </div>
                                    </div>
                                    <div class="col l-8 m-8 c-12">
                                        <div class="center">
                                            <div class="title"><h2>DỰ TOÁN {{ entity.type }}</h2></div>
                                            <div class="code"><span class="label">Số: </span><span>{{entity.estimateCode}}</span></div>
                                            <div class="date"><span class="label">Ngày: </span><span>{{entity.estimateDate}}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row no-gutters">
                                    <div class="col l-12 m-12 c-12">
                                        <div class="line-h"> 
                                            <label>Kính gửi: </label>
                                            <p>BGĐ Công ty PPL</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row no-gutters" style="padding-top:10px;">
                                    <div class="col l-12 m-12 c-12">
                                        <div class="line"> 
                                            <label>Mã phiếu: </label>
                                            <div>{{entity.estimateCode}}</div>
                                        </div>
                                        <div class="line"> 
                                            <label>Người lập phiếu: </label>
                                            <div>{{entity.staff.fullName}}</div>
                                        </div>
                                        <div v-if="entity.estimateType == 1" class="line"> 
                                            <label>Phương tiện: </label>
                                            <div>{{entity.vehicle.licensePlate}}</div>
                                        </div>
                                        <div class="line-2">
                                            <div class="line"> 
                                                <label>Nội dung: </label>
                                                <div>{{entity.estimateNote}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row no-gutters">
                                    <div class="col l-12 m-12 c-12" style="padding-top:20px;">
                                        <table class="table-1">
                                            <thead>
                                                <tr>
                                                    <th style="width:80px;">Stt</th>
                                                    <th style="width:400px;">Tên phí</th>
                                                    <th style="width:200px;">Đơn giá</th>
                                                    <th style="width:120px;">Vat</th>
                                                    <th style="width:200px;">Thành tiền</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(item, index) in entity.details" :key="'item-' + index">
                                                <tr>
                                                    <td class="stt">{{index + 1}}</td>
                                                    <td style="padding-left: 30px;">{{item.fee.feeName}}</td>
                                                    <td style="text-align: right;">{{formatNumber(item.estimatePrice)}}</td>
                                                    <td style="text-align: center;">{{formatNumber(item.estimateVat)}}%</td>
                                                    <td style="text-align: right;">{{formatNumber(item.estimateAmount)}}</td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td colspan="4" class="stt"><b>Tổng cộng</b></td>
                                                    <td style="text-align: right; font-weight: bold;">{{ formatNumber(entity.estimateAmount) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row no-gutters" style="padding-top: 50px;">
                                    <div class="col l-12 m-12 c-12">
                                        <div class="approve"> 
                                            <div v-for="item in entity.approveList" :key="item.id" class="approve-item">
                                                <div v-show="!item.isCommenter">
                                                    <div class="title">{{item.title}}</div>
                                                    <div class="approve-status">
                                                        <md-chip v-if="item.status == 1" class="md-wait">Chờ duyệt</md-chip>
                                                        <md-chip v-else-if="item.status == 2" class="md-primary"><i class="uil uil-check"></i> Đã duyệt</md-chip>
                                                        <md-chip v-else-if="item.status == 4" class="md-accent">Không phê duyệt</md-chip>
                                                    </div>
                                                    <div class="name">{{item.staff.fullName}}</div>
                                                    <div class="comment">{{item.note}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <preview ref="preview"/>
   </div>
</template>

<script>
    import estimateService from '../../../api/estimateService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';
    import comment from '../../../components/Comment.vue';
    import preview from './Preview.vue';

    export default ({
        components: {
            comment,
            preview
        },
        metaInfo: {
            title: 'Dự toán đăng kiểm'
        },
        data() {
            return {
               title: 'Dự toán đăng kiểm',
               id: 0,
               submitted: false,
               loadding: false,
               obj: {},
               comments: [],
               details: [],
               entity: { id: 0, files: [], approveList: [], details: [], staffId: '', staff: {}, estimateCode: '', estimateDate: null, status: 0, amount: 0, vat: 0, pay: 0 },
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
                this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            async preview () {
                this.$refs.preview.open(this.entity);
            },

            viewAttachFile(){
                let route = this.$router.resolve('/registration-estimate/view-attach/' + this.id);
                window.open(route.href, '_blank');
            },

            getById(){
                this.setLoading(true);
                estimateService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/registration-estimate');
            }
        },
    })

</script>

<style scoped>
    .wrapper {
        margin: 0 auto;
        width: 1000px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .header {
        display: flex;
        justify-content: space-around;
        border: 1px solid #999;
        position: relative;
    }
    .left, .center, .right {
        height: 130px;
    }
    .left {
        border-top: 1px solid #999;
        border-left: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .left img{
        height: 100px;
        width: 220px;
    }
    .center {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .center .title {
        display: flex;
    }
    .center .title h2 {
        font-size: 22px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .center .code, .center .date {
        font-size: 16px;
    }
    .right {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        padding-left: 30px;
    }
    .right .item {
        display: block;
        position: relative;
        font-size: 16px;
    }
    .right .md-checkbox {
        margin: 0 !important;
    }
    .label {
        font-weight: bold;
    }
    .line-h {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 50px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .line-h label {
        font-weight: bold;
        font-size: 24px;
        padding-right: 10px;
        text-decoration: underline;
    }
    .line-h p {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .line p {
        font-size: 18px;
        line-height: 1.2 rem;
    }
    b {
        font-weight: bold;
    }
    .content {
        font-style: italic;
        font-weight: bold;
    }
    .line-f {
        padding: 30px 0;
        display: flex;
        align-items: center;
    }
    .line-f .title {
        font-size: 18px;
        padding-right: 30px;
    }
    .line-f .option {
        display: flex;
    }
    .line-f .option .option-item {
        padding-right: 50px;
        font-size: 17px;
    }
    .approve {
        display: flex;
        justify-content: space-around;
    }
    .approve-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .approve-item .title {
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .approve-item .approve-status {
        padding: 10px 0 100px 0;
    }
    .approve-item .approve-status .md-chip {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .approve-item .approve-status i{
        color: #00d25b;
        font-size: 25px;
        padding-right: 5px;
    }
    .approve-item .name {
        font-size: 18px;
        font-weight: bold;
    }
    .note-item {
        padding-bottom: 30px;
    }
    .note-item .note-title {
        font-weight: bold;
    }
    .note-item .note {
        padding-top: 10px;
        color: red;
        font-size: 16px;
    }
    .line {
        display: flex;
        padding-top: 5px;
        align-items: center;
    }
    .line label {
        font-weight: bold;
        padding-right: 10px;
        font-size: 15px;
    }
    .line span{
        font-size: 15px;
        font-weight: bold;
    }
    .line div {
        font-size: 15px;
    }
</style>
